import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiNewBase = createApi({
  reducerPath: 'apiNewBase',
  baseQuery: fetchBaseQuery({ baseUrl: 'http://178.253.42.156:8003/api/v1/' }),
  endpoints: (builder) => ({
  parkById: builder.query({
  query: (id) => ({
   url: `parking/${id}`,
   method: 'GET',
   headers: {
    'Content-Type': 'application/json'
  },
  }),
  }),
  startSession: builder.mutation({
  query: (body) => ({
   url: `sessions/startSession`,
   method: 'POST',
   body,
  }),
  }),
  }),
 });
 
 export const { useParkByIdQuery: useParkByIdQueryNewBase } = apiNewBase;
 export const { useStartSessionMutation } = apiNewBase;