import { useState, useEffect } from 'react';

export function useWaitTime(callback) {
  const [waitTime, setWaitTime] = useState(30);
 
  useEffect(() => {
   let timer;
   if (waitTime > 0) {
     timer = setInterval(() => {
       setWaitTime(waitTime - 1);
     }, 1000);
   } else {
     clearInterval(timer);
     callback && callback();
   }
   return () => clearInterval(timer);
  }, [waitTime, callback]);
 
  return { waitTime, setWaitTime };
 }

 
 