import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

export default function Auth({ handlePhoneNumberChange }) {
 const [phone, setPhone] = useState("");

 return (
 <PhoneInput
 country={"ru"}
 enableSearch={true}
 value={phone}
 onChange={setPhone}
 isValid={(value, country) => {
  handlePhoneNumberChange(phone)
  return value.length > 0;
  
 }}
//  onBlur={() => handlePhoneNumberChange(phone)}
 />
 );
}