function Header() {
    return (
      <header>
        <div className="container">
          <h1 className="header">На Месте!</h1>
        </div>
      </header>
    );
  }

  export default Header;