function Card({ imgSrc, title, subtitle, price }) {
  return (
    <div className="card">
      <img src={imgSrc} alt="" />
      <p className="card__p">{title}</p>
      <p className="card__p">{subtitle}</p>
      <p className="card__p">{price}</p>
    </div>
  );
}
 
export default Card;