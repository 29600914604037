import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Auth from './auth'
import './style.css'
import { useCheckMobilePhoneMutation } from '../../api/api';

export default function Authorization() {

 let navigate = useNavigate();
 const [isPhoneNumberEntered, setIsPhoneNumberEntered] = useState(false);
 const [phone, setPhone] = useState("");
 const [checkMobilePhone, { error, message }] = useCheckMobilePhoneMutation();
 const [errorMessage, setErrorMessage] = useState("");
 
 const handlePhoneNumberChange = (isValid) => {
     setIsPhoneNumberEntered(isValid);
     setPhone(isValid);
 };
 
 const handleLogin = async (event) => {
    event.preventDefault();
    if (isPhoneNumberEntered) {
    try {
     await checkMobilePhone(phone);
     navigate('/parking/157749/verify', { state: { mobilePhone: phone } });
    } catch (error) {
     setErrorMessage("Неправильно введен номер телефона");
    }
    }
   };
   
    return (
    <>
    <header>
            <div className="container">
                <h1 className="header">На Месте!</h1>
            </div>
        </header>

        <section className="title_header">
            <div className="container">
                <div className="content">
                    <h1 className="content__t">Введите номер телефона</h1>
                    <p className="content__p">Авторизация позволит вам оплатить парковку после завершения</p>
                </div>
            </div>
        </section>

<div className="container">
        <Auth handlePhoneNumberChange={handlePhoneNumberChange} />
        {errorMessage && <p style={{color: 'red'}}>{errorMessage}</p>}
        </div>

        <div className="info">
            <div className="container">
                <p className="info__p">Нажимая “Авторизоваться”, Вы даете <a href="#" className="info__a">согласие на обработку персональных данных</a> и <a href="#" className="info__a">принимаете условия пользовательского соглашения</a></p>
            </div>
        </div>

        <div className="t__btn">
     <button><a href="#" className="btn" style={{ backgroundColor: isPhoneNumberEntered ? 'blue' : '#2D72FF80' }} onClick={handleLogin} disabled={!isPhoneNumberEntered}>Авторизоваться</a></button>
     </div>
    </>

  );
}
