import { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useInputValues } from './useInputValues';
import { useWaitTime } from './useWaitTime';
import { useSignInMutation, useCheckMobilePhoneMutation } from '../../api/api';
import { useParkByIdQueryNewBase } from '../../api/apiNewBase';
import { useSelector } from 'react-redux';

function VerificationCodeInput() {
 const { values, activeIndex, handleInputChange, handleKeyDown, inputs } = useInputValues();
 const [isButtonEnabled, setIsButtonEnabled] = useState(false);
 const navigate = useNavigate();
 const [signIn] = useSignInMutation();
 const [checkMobilePhone] = useCheckMobilePhoneMutation();
 const location = useLocation();
 const { waitTime, setWaitTime } = useWaitTime();
 const [errorMessage, setErrorMessage] = useState("");
 const { id } = useParams();
 const { data, error, isLoading } = useParkByIdQueryNewBase(id);
 
 useEffect(() => {
 if (values.every(value => value.length > 0)) {
  setIsButtonEnabled(true);
 } else {
  setIsButtonEnabled(false);
 }
 }, [values]);

 const handleLogin = async (event) => {
 event.preventDefault();
 if (isButtonEnabled) {
  try {
   const result = await signIn({ mobilePhone: location.state.mobilePhone, valueCode: values.join('') });
   localStorage.setItem('UserToken', result.data.message.client.token);
   navigate('/parking/157749/park', { state: { parkingData: data } });
  } catch (error) {
    setErrorMessage("Неверный код доступа");
  }
 }
 };

 const handleResendCode = async () => {
 try {
  await checkMobilePhone(location.state.mobilePhone);
 } catch (error) {
  console.error(error);
 }
 };

 const styles = {
 input: {
  width: '52px',
  height: '52px',
  padding: '12px',
  marginTop: '46px',
  boxSizing: 'border-box',
  textAlign: 'center',
  border: 'none',
  outline: 'none',
 },
 activeInput: {
  borderBottom: '2px solid #1763FF',
 },
 submitButton: {
  fontWeight: 600,
  fontSize: '18px',
  height: '50px',
  background: isButtonEnabled ? '#007BFF' : '#2D72FF80',
  borderRadius: '25px',
  color: 'white',
  padding: '12px 20px',
  marginTop: '74px',
  border: 'none',
  cursor: 'pointer',
  width: '100%',
 },
 submitButtonHovered: {
  opacity: 0.8,
 },
 };

 return (
    <form>
    <div className="form">
     {values.map((value, i) => (
      <input key={i} type="text" id={`vcode${i+1}`} name={`vcode${i+1}`} placeholder="•" maxLength="1" value={value} onChange={(e) => handleInputChange(e, i)} onKeyDown={(e) => handleKeyDown(e, i)} ref={(el) => inputs.current[i] = el} style={{...styles.input, ...(i === activeIndex ? styles.activeInput : {})}} />
     ))}
     {errorMessage && <p style={{color: 'red'}}>{errorMessage}</p>}
     <br />
     <input type="submit" onClick={handleLogin} value="Продолжить" style={styles.submitButton} onMouseEnter={() => styles.submitButton = styles.submitButtonHovered} onMouseLeave={() => styles.submitButton = styles.submitButton} />
     <h1 className="form__h" onClick={() => {if (waitTime === 0) {handleResendCode(); setWaitTime(30);}}}>
      {waitTime > 0 ? (
       `Отправить код повторно 00:${String(waitTime).padStart(2, '0')}`
      ) : (
       "Отправить код снова"
      )}
     </h1>
    </div>
    </form>
   );
}

export default VerificationCodeInput;
