import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './api';
import { apiNewBase } from './apiNewBase';

const store = configureStore({
 reducer: {
 [apiSlice.reducerPath]: apiSlice.reducer,
 [apiNewBase.reducerPath]: apiNewBase.reducer,
 },
 middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware).concat(apiNewBase.middleware),
});

export default store;
