import Vk from '../../assets/vk.png';
import Inst from '../../assets/inst.png';

function Footer() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="footer__img">
            <img className="img_f" src={Vk} alt="" />
            <img className="img_f" src={Inst} alt="" />
          </div>
          <div className="footer__info">
            <h2 className="footer__i">Политика конфиденциальности</h2>
            <h2 className="footer__i">Написать в поддержку</h2>
            <h2 className="footer__i">@2023 На Месте!</h2>
          </div>
        </div>
      </footer>
    );
  }
  
  export default Footer;