import { useState, useRef } from 'react';

export function useInputValues() {
 const [values, setValues] = useState(['', '', '', '']);
 const [activeIndex, setActiveIndex] = useState(0);
 const inputs = useRef([]);

 const handleInputChange = (e, i) => {
  const newValues = [...values];
  newValues[i] = e.target.value;
  setValues(newValues);
  if (e.target.value.length === e.target.maxLength && i < inputs.current.length - 1) {
    setActiveIndex(i + 1);
    inputs.current[i + 1].focus(); // Добавлено
  }
 };

 const handleKeyDown = (e, i) => {
  if (e.key === 'Backspace' && e.target.value.length === 0 && i > 0) {
   setActiveIndex(i - 1);
   inputs.current[i - 1].focus(); // Добавлено
  } else if (e.key === 'ArrowRight' && i < inputs.current.length - 1) {
   setActiveIndex(i + 1);
   inputs.current[i + 1].focus();
  } else if (e.key === 'ArrowLeft' && i > 0) {
   setActiveIndex(i - 1);
   inputs.current[i - 1].focus();
  }
 };

 return { values, activeIndex, handleInputChange, handleKeyDown, inputs };
}