function InfoBlock({ workTime, tariffs }) {
    return (
      <div className="container">
        <div className="block__info">
          <div className="block__time">
            <p>Время работы</p>
            <p>{workTime}</p>
          </div>
          <div className="block__tarif">
            <p>{tariffs}</p>
          </div>
        </div>
      </div>
    );
  }

  export default InfoBlock;