function TitleHeader({ title, subtitle }) {
    return (
      <section className="title_header">
        <div className="container">
          <div className="content">
            <h1 className="content__t">{title}</h1>
            <p className="content__p">{subtitle}</p>
          </div>
        </div>
      </section>
    );
  }

  export default TitleHeader;