import TitleHeader from './TitleHeader';
import VerificationCodeInput from './VerificationCodeInput';
import { useLocation } from "react-router-dom";
import Header from '../../components/header/header';
import './style.css'

function Verification() {
 const location = useLocation();

 return (
   <>
     <Header />
     <TitleHeader title="Введите код авторизации" subtitle={`Мы отправили код на номер ${location.state.mobilePhone}`} />
     <div className="container">
       <div className="form-container">
         <VerificationCodeInput />
       </div>
     </div>
   </>
 );
}

export default Verification;
