import React, { useState, useEffect } from 'react';
import Vk from '../assets/vk.png';
import Inst from '../assets/inst.png';
import Phone from '../assets/iPhone.png';
import Car from '../assets/car.png';
import AccordionItem from '../components/accordionItem/accordionItem';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';


function Accordion() {
  return (
    <div className="container">
      <div className="accordion">
        <AccordionItem id={1} question="Как заехать на парковку?" answer="Чтобы заехать на парковку нажмите кнопку “Открыть шлагбаум”." />
        <AccordionItem id={2} question="Как заехать на парковку?" answer="Чтобы заехать на парковку нажмите кнопку “Открыть шлагбаум”." />
        <AccordionItem id={3} question="Как заехать на парковку?" answer="Чтобы заехать на парковку нажмите кнопку “Открыть шлагбаум”." />
        <AccordionItem id={4} question="Как заехать на парковку?" answer="Чтобы заехать на парковку нажмите кнопку “Открыть шлагбаум”." />
        <AccordionItem id={5} question="Как заехать на парковку?" answer="Чтобы заехать на парковку нажмите кнопку “Открыть шлагбаум”." />
        <AccordionItem id={6} question="Как заехать на парковку?" answer="Чтобы заехать на парковку нажмите кнопку “Открыть шлагбаум”." />

      </div>
    </div>
  );
}


function Main() {

  const [scrollY, setScrollY] = useState(0);
  const [buttonStyle, setButtonStyle] = useState({});
  const [aStyle, setAStyle] = useState({});


  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  useEffect(() => {
    
    const button = document.querySelector('.btn__app');
    const target = document.querySelector('.btn__b');
    if (button && target) {
      const buttonTop = button.getBoundingClientRect().top + scrollY;
      const targetTop = target.getBoundingClientRect().top + scrollY - window.innerHeight;
      console.log(targetTop)
      if (scrollY >= buttonTop && scrollY <= targetTop) {
        setButtonStyle({
          position: 'fixed',
          bottom: '0px',
          transform: 'none',
          transition: 'all 0.5s ease-in-out',
        });
        
      } else if (scrollY > 1300) {

        setButtonStyle({
          position: 'absolute',
          top: `${targetTop + window.innerHeight}px`,
          transition: 'top 0.5s ease-in-out', 
          
        });
        
        setAStyle({
          backgroundColor: 'white',
          color: '#1763FF',
        })
        
      } else {
        setButtonStyle({
          position: 'fixed',
          transition: 'top 0.5s ease-in-out',
          

        });
        setAStyle({
          backgroundColor: '#1763FF',
        })
      }
    }
  }, [scrollY]);

  return (
    <main>
      <section className="container">
        <div className="title__block__container">
          <div className="title__title">
            <h1 className="title__t">Чтобы завершить парковку, скачайте приложение</h1>
          </div>
          <div className="title__paragraf">
            <p className="title__p">Паркуйтесь вместе с На Месте и экономьте свое время!</p>
          </div>
          <div className="title__img">
            <img src={Phone} alt="" />
          </div>
          <div className="title__btn">
        <div className='btn__a' style={buttonStyle}>
          <a style={aStyle} href="#" className="btn__app">Установить приложение</a>
        </div>
      </div>
        </div>
      </section>

      <div>
        <div className="container">
            <h1 className="title__header">Как пользоваться?</h1>
        </div>

        <section className="container">
            <div className="block-one">
                <div className="block-one__title">
                    <h1 className="block__t">Установите приложение</h1>
                </div>
            </div>
        </section>

        <section className="container">
            <div className="block-two">
                <div className="block-two__title">
                    <h1 className="block__t">Найдите удобную вам парковку</h1>
                </div>
            </div>
        </section>

        <section className="container">
            <div className="block-three">
                <div className="block-three__title">
                    <h1 className="block__t">Оплата спишется автоматически при выезде</h1>
                </div>
            </div>
        </section>

        <section className="container">
            <div className="block-four">
                <div className="block-four__title">
                    <h1 className="block__t">Заедьте по кнопке «Открыть шлагбаум»</h1>
                </div>
            </div>
        </section>

        <section className="title__block container">
            <div className="one__block__container">
                <div className="section__title">
                    <h1 className="section__t">паркуйтесь вместе с<br /><span className="section_span">НА МЕСТЕ!</span></h1>
                </div>

                <div className="section__img">
                    <img src={Car} alt="" />
                </div>

                <div className="section__paragraf">
                    <p className="section__p">Оплачивайте парковку прямо с телефона. Не выходя из машины.</p>
                </div>

                <div className="section__btn btn__b">
                </div>
            </div>
        </section>

        <div className="container">
            <h1 className="title__header">Ответы на вопросы</h1>
        </div>
    </div>

    </main>
  );
}

function SecondPages() {
  return (
    <>
    <Header />
    <Main />
    <Accordion />
    <Footer />
    </>
  );
}

export default SecondPages;
