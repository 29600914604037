import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
 reducerPath: 'api',
 baseQuery: fetchBaseQuery({ baseUrl: 'https://nameste.pro/api/v1/' }),
 endpoints: (builder) => ({
 checkMobilePhone: builder.mutation({
  query: (mobilePhone) => ({
    url: `auth/check`,
    method: 'POST',
    credentials: "same-origin", 
    headers: {
      'Content-Type': 'application/json'
    },
    body: { mobile_phone: `+${mobilePhone}` },
  }),
 }),
 signIn: builder.mutation({
  query: ({ mobilePhone, valueCode }) => ({
    url: `auth/sign`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ mobile_phone: `+${mobilePhone}`, value_code: valueCode }),
  }),
 }),
 }),
});




export const { useCheckMobilePhoneMutation, useSignInMutation } = apiSlice;
