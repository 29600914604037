import React, { useState } from 'react';

function AccordionItem({ id, question, answer }) {
    const [isOpen, setIsOpen] = useState(false);
  
    const handleClick = () => {
      setIsOpen(!isOpen);
    };
  
    return (
      <div className={`accordion-item ${isOpen ? 'open' : ''}`} onClick={handleClick}>
        <button id={`accordion-button-${id}`} aria-expanded={isOpen}>
          <span className={`according__btn ${isOpen ? 'open' : ''}`}>{question}</span>
          <span className="arrow" style={{ transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)' }}>›</span>
        </button>
        <div className="accordion-content" aria-labelledby={`accordion-button-${id}`} style={{ maxHeight: isOpen ? '100%' : '0', overflow: 'hidden' }}>
          <p className="acc__p">{answer}</p>
        </div>
      </div>
    );
  }

  export default AccordionItem;