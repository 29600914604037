import Car from '../../assets/car-img.png'

function CarImage() {
    return (
      <div className="car__img">
        <img className="car__i" src={Car} alt="" />
      </div>
    );
  }

export default CarImage;