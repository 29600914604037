import React, { useState, useEffect } from "react";
import { useStartSessionMutation } from '../../api/apiNewBase';
import { jwtDecode } from "jwt-decode";
import { CircularProgress } from '@mui/material';
import './styles.css'
import Card1 from '../../assets/icon.svg'
import Card2 from '../../assets/icon1.svg'
import Card3 from '../../assets/icon2.svg'
import Card4 from '../../assets/icon3.svg'
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Header from "../../components/header/header";
import Card from "../../components/card/card";
import InfoBlock from "./InfoBlock"
import TitleHeader from "./TitleHeader"
import CarImage from "./CarImage";


function CardsBlock() {
  return (
    <div className="block__cards">
      <Card imgSrc={Card1} title="Бесплатный" subtitle="первый час" price="0 руб" />
      <Card imgSrc={Card2} title="Почасовой" subtitle="каждый час" price="100 руб/час" />
      <Card imgSrc={Card3} title="Длительный" subtitle="от 4 до 10 ч." price="600 руб" />
      <Card imgSrc={Card4} title="Суточный" subtitle="от 10 до 24 ч." price="1100 руб" />
    </div>
  );
}


function OpenButton() {
  const { id } = useParams();
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [startSession] = useStartSessionMutation();
  const token = localStorage.getItem('UserToken');
  const decodedToken = jwtDecode(token);
  const userID = decodedToken.user_id;
 
  const handleLogin = async (event) => {
   setIsLoading(true);
   console.log(decodedToken)
   console.log(decodedToken.user_id)
   event.preventDefault();
   try {
    const body = {
     parking: id,
     userID: userID,
     cardID: "234234234234",
    };
    await startSession(body);
    navigate('/parking/second');
   } catch (error) {
    console.error(error);
   } finally {
    setIsLoading(false);
   }
  };
 
  return (
    <div className="container">
    <div className="open__btn">
      <div onClick={handleLogin} className="title__b">
          {isLoading ? (
                        <a href="#" className="btn2">
                                    <CircularProgress color="inherit" size={24} />
                        </a>
          ) : (
            <a href="#" className="btn1">
            "Открыть шлагбаум"
            </a>
          )}
        
      </div>
    </div>
  </div>
  );
 }



function Parking() {
  const location = useLocation();
  console.log(location.state?.parkingData);
  const [parkingData, setParkingData] = useState(location.state?.parkingData);

  useEffect(() => {
    setParkingData(location.state?.parkingData);
    }, [location]);


 return (
 <>
 
 <Header />
 {parkingData ? <>
 <TitleHeader title={parkingData.city} number={parkingData.ID} address={parkingData.address} />
 
 <InfoBlock workTime={parkingData.workingTime} tariffs={parkingData.tariff} /></> : <></>}
 
 
 <CardsBlock />
 
 <CarImage />
 
 <OpenButton />
 
 </>
 );
}

export default Parking;
